.container.testimonials__container {
  width: 40%;
}

.colleague__avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 2px solid var(--color-primary-variant);
}

.testimonial {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.colleague__position {
  font-size: 1rem;
}

.colleague__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.slider-control-bottomcenter {
  top: 10px;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }

  .colleague__review {
    width: var(--container-width-sm);
  }
}
