.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 10%;
}

.about__illustration-container {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  align-self: center;
}

.about__illustration {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  background: var(--color-bg-variant);
  transition: var(--transition);
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

.about__illustration:hover {
  transform: rotate(0deg)
}

.react-logo {
  position: absolute;
  width: 65%;
  top: -50px;
  right: -40px;
  -webkit-animation: rotate-center 45s linear infinite both;
	animation: rotate-center 45s linear infinite both;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  min-width: 175px;
  background: var(--color-bg-variant);
  border: 2px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: .95rem;
}

.about__card small {
  font-size: .7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1125px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__illustration-container {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 650px) {
  .about__illustration-container {
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about__card {
    width: 50px;
    min-height: 188px;
    padding: 1rem;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    place-items: center;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}

/* ====== MEDIA QUERIES (XSMALL DEVICES) ====== */
@media screen and (max-width: 400px) {
  .about__cards {
    grid-template-columns: 1fr;
  }

  .about__card {
    width: 100%;
    min-height: 50px;
  }
}