footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 6rem;
}

footer a {
  color: var(--color-bg);
}

.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.footer__made-with small {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.footer__made-with {
  margin-bottom: 4rem;
  text-align: center;
  color: var(--color-bg);
  display: flex;
  justify-content: center;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

/* Off white icon color */
.footer__icon {
  background: var(--color-bg);
  color: #e8e6e6;
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 2px solid transparent;
  transition: var(--transition);
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer__socials {
    margin-bottom: 2.6rem;
  }
}
