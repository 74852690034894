header {
  height: max-content;
  padding-top: 7rem;
  overflow: hidden;
}

header > a {
  font-size: 1rem;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ====== CTA ====== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
}

/* ====== HEADER SOCIALS ====== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: fixed;
  left: 1.8rem;
  bottom: 4rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.socials__bottom {
  color: var(--color-bg);
}

.socials__bottom a {
  color: var(--color-bg);
}

.socials__bottom::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-bg);
}

/* ====== PROFILE PICTURE ====== */
.profile {
  margin: 0 auto;
  width: 22rem;
  height: min-content;
  z-index: 1;
  overflow: hidden;
}

.profile::before {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(77, 181, 255, 1) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  width: 350px;
  height: 73%;
  position: absolute;
  z-index: -1;
  transform: translate(-50%, 20%);
  border-radius: 12rem 12rem 0 0;
}

.profile__img {
  height: 300px;
}

/* ====== SCROLL DOWN & EMAIL ====== */
.email,
.scroll-down {
  position: fixed;
}

.email {
  font-weight: 300;
  font-size: 0.9rem;
  transition: 400ms ease;
  transform: rotate(90deg);
  bottom: 15rem;
  right: -4rem;
}

.email:hover {
  font-size: 0.91rem;
  transform: rotate(90deg);
}

.scroll-down {
  bottom: 4rem;
  right: 1.5rem;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
  section > h2 {
    margin-bottom: 2rem;
  }
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 930px) {
  .header__socials,
  .scroll-down,
  .email {
    display: none;
  }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 650px) {
  header {
    padding-top: 4rem;
  }

  .profile {
    width: 300px;
  }
}

/* ====== MEDIA QUERIES (XSMALL DEVICES) ====== */
@media screen and (max-width: 480px) {
  header {
    padding-top: 3.7rem;
  }

  .header__container > h1 {
    font-size: 1.8rem;
  }

  .header__socials,
  .scroll-down,
  .email {
    display: none;
  }

  .profile {
    width: 270px;
  }

  .btn {
    font-size: 0.7rem;
  }

  .cta {
    gap: 0.5rem;
  }

  .profile::before {
    width: 310px;
    height: 60%;
    position: absolute;
    z-index: -1;
    transform: translate(-50%, 36%);
    border-radius: 12rem 12rem 0 0;
  }
}
