.projects__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.projects__item {
  display: flex;
  flex-direction: column;
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 2px solid transparent;
  transition: var(--transition);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.projects__item .btn {
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
}

.projects__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.projects__item-img {
  border-radius: 1.5rem;
  overflow: hidden;
}

.projects__item h3 {
  font-weight: 300;
  font-size: 1rem;
  margin: 1.2rem 0 2rem;
}

.projects__item-cta {
  display: flex;
  margin-top: auto;
  gap: 1rem;
}

.projects__tech {
  color: var(--color-primary);
  font-weight: 500;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1100px) {
  .projects__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 650px) {
  .projects__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
